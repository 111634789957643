import { type ChangeEvent, useContext, useState, useRef, useEffect } from 'react';
import { MdArrowForwardIos, MdClose, MdCheckCircle } from 'react-icons/md';
import { Cart } from 'shared/types/cart';
import { useCart } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { getPriceSummary } from '~/helpers/furniturechoice/displayPriceHelper';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sendGA4Event } from '~/utils/send-ga4-event';
import FNCButton from '../fnc/fnc-button';
import FNCInput from '../fnc/fnc-input';
import GA4Button from '../ga4-button';

export default function UniqueCustomerCode() {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const cartApi = useCart();
  const cart = cartApi.data as Cart;
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const APPLY = formatMessage({ id: 'components.unique-customer-code.apply' });
  const APPLIED = formatMessage({ id: 'components.unique-customer-code.applied' });

  const activeDiscountCode = useRef(
    cart.discountCodes && cart.discountCodes.length ? (cart.discountCodes[0].code as string) : '',
  );
  const currentActiveDiscount =
    cart.discountCodes && cart.discountCodes.length ? (cart.discountCodes[0].code as string) : '';
  const [isVisible, setIsVisible] = useState(currentActiveDiscount ? true : false);
  const [discountBoxClass, setDiscountBoxClass] = useState(
    currentActiveDiscount ? 'h-[128px] opacity-1' : 'h-0 opacity-0',
  );
  const [arrowClass, setArrowClass] = useState(currentActiveDiscount ? 'rotate-90' : 'rotate-0');
  const [buttonDisabled, setButtonDisabled] = useState(currentActiveDiscount ? true : false);
  const [errorState, setErrorState] = useState({
    message: '',
    textboxClass: '',
    buttonClass: '',
  });
  const [buttonText, setButtonText] = useState(currentActiveDiscount ? APPLIED : APPLY);
  const [inputReadonly, setInputReadonly] = useState(currentActiveDiscount ? true : false);
  const [circleStyle, setCircleStyle] = useState({ display: 'none', marginLeft: '0px' });
  const code = useRef('');
  const { discountAmount } = getPriceSummary(cartApi);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // check for active discount
    if (currentActiveDiscount) {
      // discount must be above 0 otherwise it is considered invalid
      if (discountAmount <= 0) {
        removeDiscountCode(setError, formatMessage({ id: 'components.unique-customer-code.this_code_is_invalid' }));
      } else {
        //this is to set the green tick
        const w = measureInputText();
        setCircleStyle({ marginLeft: w + 'px', display: 'block' });
      }
    } else {
      setCircleStyle({ display: 'none', marginLeft: '0px' });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveDiscount]);

  // this is used to measure the length of the text inside the discount code input box
  const measureInputText = () => {
    const input = inputRef.current as HTMLInputElement;
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    if (ctx) {
      ctx.font = '16px Arial';
      const txtWidth = ctx.measureText(input?.value as string).width;
      return txtWidth + 20;
    } else {
      return 20;
    }
  };

  // handles clicking of 'Add unique customer code >'
  const clickHandler = () => {
    if (isVisible) {
      setDiscountBoxClass('h-[128px] opacity-1');
      setArrowClass('rotate-90');
    } else {
      setDiscountBoxClass('h-0 opacity-0');
      setArrowClass('rotate-0');
    }
    setIsVisible(!isVisible);
  };

  // handles text change in discount code text box
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length) {
      setButtonDisabled(false);
    } else {
      sendGA4Event('customer_code', 'interaction_type', 'Remove Code');
      setButtonDisabled(true);
    }
    code.current = e.target.value;
  };

  // resets discount code to blank
  const clearDiscountCode = () => {
    setErrorState({
      message: '',
      textboxClass: '',
      buttonClass: '',
    });
    setInputReadonly(false);
    setButtonText(APPLY);
    activeDiscountCode.current = '';
    code.current = '';
    const custCodeInput = inputRef.current;
    if (custCodeInput) {
      custCodeInput.value = '';
    }
  };

  // sets an error state
  const setError = (msg: string) => {
    setErrorState({
      message: msg,
      textboxClass: 'text-red-1 border-red-1',
      buttonClass: 'surface-red-1',
    });
    setInputReadonly(false);
  };

  // api call to remove discount code
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removeDiscountCode = async (fn: (p?: any) => void, param?: any) => {
    const cart = cartApi.data || null;
    const discountCode = cart && cart.discountCodes && cart.discountCodes.length ? cart.discountCodes[0] : null;
    if (!discountCode) return;
    await executeWithProgress(async () => {
      try {
        if (cartApi.removeDiscountCode) {
          await cartApi.removeDiscountCode(discountCode);
          fn(param);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e.message);
      }
    });
  };

  // click handler to remove discount code
  const handleRemove = async () => {
    await removeDiscountCode(clearDiscountCode);
  };

  // click handler to submit discount code
  const handleSubmit = async () => {
    await executeWithProgress(async () => {
      try {
        if (cartApi.redeemDiscountCode) {
          await cartApi.redeemDiscountCode(code.current);
          setErrorState({
            message: '',
            textboxClass: '',
            buttonClass: '',
          });
          setInputReadonly(false);
          sendGA4Event('customer_code', 'interaction_type', 'Valid Code');
        }
      } catch (e) {
        sendGA4Event('customer_code', 'interaction_type', 'Invalid Code');
        setError(formatMessage({ id: 'components.unique-customer-code.please_enter_a_valid_code' }));
      }
    });
  };

  const GetActiveDiscount = ({ activeDiscountCode }: { activeDiscountCode: string }) => {
    if (activeDiscountCode) {
      return (
        <div>
          <button
            title={formatMessage({
              id: 'components.unique-customer-code.unique_customer_code',
              defaultMessage: 'Unique customer code',
            })}
            onClick={handleRemove}
            className="my-4 rounded-sm bg-grey-9 p-2 text-14 font-semibold text-grey-6"
          >
            <span className="">{activeDiscountCode}</span> <MdClose className="inline" />
          </button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div id="uniqueCustCodeContainer" className="text-grey-5">
        <GA4Button
          ga4_event_name="customer_code"
          ga4_event_param_1_name="interaction_type"
          ga4_event_param_1_value={isVisible ? 'Open' : 'Close'}
          className="cursor-pointer text-16 text-grey-5 disabled:cursor-auto"
          onClick={clickHandler}
        >
          {formatMessage({
            id: 'components.unique-customer-code.add_unique_customer_code',
            defaultMessage: 'Add unique customer code',
          })}
        </GA4Button>
        <MdArrowForwardIos className={'ml-1 inline transition-all duration-300 ' + arrowClass} size={10} />
      </div>
      <div className={'py-4 transition-all duration-300 ' + discountBoxClass}>
        <div className="grid grid-cols-[1fr_120px]">
          <div>
            <MdCheckCircle
              style={circleStyle}
              id="checkCircle"
              className="pointer-events-none absolute z-10 mt-[27px] text-green-2"
            />
            <FNCInput
              ref={inputRef}
              onEveryChange={changeHandler}
              inputClassName={'rounded-l rounded-r-none ' + errorState.textboxClass}
              label={formatMessage({
                id: 'components.unique-customer-code.unique_customer_code',
                defaultMessage: 'Unique customer code',
              })}
              defaultValue={currentActiveDiscount}
              readOnly={inputReadonly}
            />
          </div>
          <div>
            <FNCButton
              onClick={handleSubmit}
              disabled={buttonDisabled}
              className={'surface-grey-4 h-[58px] rounded-l-none rounded-r ' + errorState.buttonClass}
            >
              {buttonText}
            </FNCButton>
          </div>
        </div>
        <GetActiveDiscount key={currentActiveDiscount} activeDiscountCode={currentActiveDiscount} />
        <div className="py-2 text-14 text-red-1">{errorState.message}</div>
      </div>
    </>
  );
}
